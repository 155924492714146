import { Component } from '@angular/core';
import {Apollo, gql} from "apollo-angular";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  loading = false;
  firstName: string = '';
  lastName: string = '';
  company: string = '';
  email: string = '';
  message: string = '';


  constructor(private apollo: Apollo) {
  }

  onSend() {
    this.loading = true;
    const {email, lastName , firstName , company, message} = this;
    this.apollo.mutate({
      mutation: gql`
      mutation requestContact($input: RequestContactInput!){
        requestContact(input: $input) {
          boolean
        }
      }`, variables: {
        input: {
          input: {
            company,
            email,
            firstName,
            lastName,
            message,
          }
        }
      }
    }).subscribe(({data}) => {
      this.loading = false;
    })
  }

}
