import {Component, Input} from '@angular/core';
import {SteperSection} from "../../../generated/cms/graphql";

@Component({
  selector: 'app-page-stepper-content',
  templateUrl: './page-stepper-content.component.html',
  styleUrls: ['./page-stepper-content.component.scss']
})
export class PageStepperContentComponent {

  @Input()
  stepper?: SteperSection

  getIsEven(n?: any) {
    return n%2 === 0;
  }
}
