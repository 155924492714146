import {Component, Input} from '@angular/core';
import {Page} from "../../../generated/cms/graphql";

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {

  @Input()
  page?: Page;
}
