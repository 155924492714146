import { NgModule } from '@angular/core';
import { HomeComponent } from './home.component';
import {SharedModule} from "../shared/shared.module";



@NgModule({
    declarations: [
        HomeComponent
    ],
    exports: [],
    imports: [
        SharedModule,
    ]
})
export class HomeModule { }
