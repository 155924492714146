import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {DefaultPageComponent} from "./pages/default-page/default-page.component";

const routes: Routes = [
  {
    path: '',
    component: DefaultPageComponent
  },
  {
    path: ':slug',
    component: DefaultPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
