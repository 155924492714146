import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {Page} from "../../generated/cms/graphql";
import {CmsService} from "../cms/cms.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit{

  pages$: Observable<Page[]> = new Observable<Page[]>()

  constructor(private cmsService: CmsService) {
  }

  ngOnInit() {
    this.pages$ = this.cmsService.getPages({
      showFooter: true
    });
  }
}
