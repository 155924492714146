import { Component } from '@angular/core';
import {Apollo, gql} from "apollo-angular";
import {RequestContactGQL} from "../../generated/app/graphql";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  loading = false;
  firstName: string = '';
  lastName: string = '';
  company: string = '';
  email: string = '';
  message: string = '';


  constructor(private requestContactGQL: RequestContactGQL) {
    this.requestContactGQL.client = 'api';
  }


  onSend() {
    this.loading = true;
    const {email, lastName , firstName , company, message} = this;
    this.requestContactGQL.mutate({
        input: {
          input: {
            company,
            email,
            firstName,
            lastName,
            message,
          }
        }
    }).subscribe(({data, loading}) => {
      if(data?.requestContact.boolean) {
        this.firstName = '';
        this.lastName = '';
        this.company = '';
        this.email = '';
        this.message = '';
      }
      this.loading = loading;
    })
  }
}
