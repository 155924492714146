<div class="bg-primary px-4 py-8 md:px-6 lg:px-8">
  <div class="container flex flex-wrap">
    <div class="w-12 lg:w-6 p-4"><h1 class="text-4xl font-bold text-white mt-0 mb-3">{{page?.header}}</h1>
      <p class="text-2xl text-gray-400 mt-0 mb-5">{{page?.subHeader}}</p>
      <!--<ul class="list-none p-0 m-0">
        <li class="mb-3 flex align-items-center"><i class="pi pi-compass text-yellow-500 text-xl mr-2"></i><span
          class="text-gray-400 line-height-3">Senectus et netus et malesuada fames.</span></li>
        <li class="mb-3 flex align-items-center"><i class="pi pi-map text-yellow-500 text-xl mr-2"></i><span
          class="text-gray-400 line-height-3">Orci a scelerisque purus semper eget.</span></li>
        <li class="mb-3 flex align-items-center"><i class="pi pi-calendar text-yellow-500 text-xl mr-2"></i><span
          class="text-gray-400 line-height-3">Aenean sed adipiscing diam donec adipiscing tristique.</span></li>
      </ul> -->
      <button pButton pRipple label="Jetzt Kontaktieren" href="#contact"
              class="block mt-7 mb-7 lg:mb-0 p-button-secondary p-button-lg font-medium"></button>
    </div>
    <div class="w-12 lg:w-6 text-center lg:text-right overflow-hidden"><img
      [src]="page?.headerImage?.url" alt="Image" class="w-full"></div>
  </div>
</div>
