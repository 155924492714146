import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CmsService} from "../../cms/cms.service";
import {Observable} from "rxjs";
import {Page} from "../../../generated/cms/graphql";

@Component({
  selector: 'app-default-page',
  templateUrl: './default-page.component.html',
  styleUrls: ['./default-page.component.scss']
})
export class DefaultPageComponent implements OnInit{

  page$: Observable<Page> = new Observable<Page>();
  constructor(
    private router: ActivatedRoute,
    private cmsService: CmsService
  ) {
  }

  ngOnInit() {
    this.router.paramMap.subscribe(param => {
      let slug = param.get('slug');
      if(!slug) {
        slug = 'home'
      }
      this.page$ = this.cmsService.getPage({
        slug
      })
    })
  }

}
