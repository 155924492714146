import {Component, OnInit} from '@angular/core';
import {CmsService} from "../cms/cms.service";
import {Observable} from "rxjs";
import {Page} from "../../generated/cms/graphql";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit{

  pages$: Observable<Page[]> = new Observable<Page[]>()
  constructor(private cmsService: CmsService) {
  }

  ngOnInit() {
    this.pages$ = this.cmsService.getPages({
      showHeader: true
    })
  }
}
