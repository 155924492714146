<div class="relative p-6 overflow-hidden">
  <div class="flex justify-content-evenly">
      <div class="text-center my-6">
        <div class="text-6xl font-bold mb-1">The Platform For</div>
        <div class="text-6xl text-primary font-bold mb-4">Today's Generation</div>
        <p class="mt-0 mb-4 line-height-3 text-center mx-auto" style="max-width:500px">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

        <button class="p-button" pButton pRipple label="Learn More" type="button">OK</button>

        <p class="text-sm mt-4 mb-4 line-height-3">Available for MacOS, Web and Google accounts only</p>
        <div class="flex justify-content-center align-items-center">
          <a href="https://www.apple.com" class="text-white mr-3">
            <i class="pi pi-apple text-2xl"></i>
          </a>
          <a href="https://play.google.com" class="text-white mr-3">
            <i class="pi pi-android text-2xl"></i>
          </a>
          <a href="https://www.facebook.com" class="text-white">
            <i class="pi pi-facebook text-2xl"></i>
          </a>
        </div>
      </div>
    <div>
      <img src="assets/images/landing.png" alt="Image" class="w-auto h-full block md:w-full">
    </div>
  </div>
</div>
<div class="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
  <div class="mb-3 font-bold text-3xl">
    <span class="text-900">One Product, </span>
    <span class="text-blue-600">Many Solutions</span>
  </div>
  <div class="text-700 mb-6">Ac turpis egestas maecenas pharetra convallis posuere morbi leo urna.</div>
  <div class="grid">
    <div class="col-12 md:col-4 mb-4 px-5">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <i class="pi pi-desktop text-4xl text-blue-500"></i>
            </span>
      <div class="text-900 text-xl mb-3 font-medium">Built for Developers</div>
      <span class="text-700 line-height-3">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</span>
    </div>
    <div class="col-12 md:col-4 mb-4 px-5">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <i class="pi pi-lock text-4xl text-blue-500"></i>
            </span>
      <div class="text-900 text-xl mb-3 font-medium">End-to-End Encryption</div>
      <span class="text-700 line-height-3">Risus nec feugiat in fermentum posuere urna nec. Posuere sollicitudin aliquam ultrices sagittis.</span>
    </div>
    <div class="col-12 md:col-4 mb-4 px-5">
            <span class="p-3 sh1adow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <i class="pi pi-check-circle text-4xl text-blue-500"></i>
            </span>
      <div class="text-900 text-xl mb-3 font-medium">Easy to Use</div>
      <span class="text-700 line-height-3">Ornare suspendisse sed nisi lacus sed viverra tellus. Neque volutpat ac tincidunt vitae semper.</span>
    </div>
    <div class="col-12 md:col-4 mb-4 px-5">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <i class="pi pi-globe text-4xl text-blue-500"></i>
            </span>
      <div class="text-900 text-xl mb-3 font-medium">Fast & Global Support</div>
      <span class="text-700 line-height-3">Fermentum et sollicitudin ac orci phasellus egestas tellus rutrum tellus.</span>
    </div>
    <div class="col-12 md:col-4 mb-4 px-5">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <i class="pi pi-github text-4xl text-blue-500"></i>
            </span>
      <div class="text-900 text-xl mb-3 font-medium">Open Source</div>
      <span class="text-700 line-height-3">Nec tincidunt praesent semper feugiat. Sed adipiscing diam donec adipiscing tristique risus nec feugiat. </span>
    </div>
    <div class="col-12 md:col-4 md:mb-4 mb-0 px-3">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <i class="pi pi-shield text-4xl text-blue-500"></i>
            </span>
      <div class="text-900 text-xlmb-3 font-medium">Trusted Securitty</div>
      <span class="text-700 line-height-3">Mattis rhoncus urna neque viverra justo nec ultrices. Id cursus metus aliquam eleifend.</span>
    </div>
  </div>
</div>
<div class="surface-section px-4 pb-8 md:px-6 lg:px-8">
  <div class="p-6 shadow-2 flex flex-column md:flex-row align-items-center justify-content-between" style="border-radius: 1rem; background: linear-gradient(0deg, rgba(0, 123, 255, 0.5), rgba(0, 123, 255, 0.5)), linear-gradient(92.54deg, #1C80CF 47.88%, #FFFFFF 100.01%)">
    <div class="pr-6">
      <div class="text-blue-100 font-medium text-xl mb-3">TAKE THE NEXT STEP</div>
      <div class="text-white font-medium text-5xl">Enpower your customer experience</div>
    </div>
    <div class="mt-4 mr-auto md:mt-0 md:mr-0">
      <button pButton pRipple label="Get Started" class="font-bold px-5 py-3 p-button-warning p-button-rounded p-button-raised white-space-nowrap"></button>
    </div>
  </div>
</div>
<div class="surface-section px-4 py-8 md:px-6 lg:px-8">
  <div>
    <div class="text-900 mb-3 font-bold text-4xl text-center">Trusted By</div>
    <div class="text-600 mb-6 line-height-3 text-xl text-center">We provide many of the Planet Earth’s most powerful companies with extraordinary infrastructural capabilities.</div>

    <div class="grid">
      <div class="col-12 md:col-6 lg:col-3">
        <div class="flex align-items-center justify-content-center p-3">
          <img src="assets/images/blocks/logos/alfred-500.svg" alt="Image">
        </div>
      </div>
    </div>
  </div>
</div>

