import { NgModule } from '@angular/core';
import { DefaultPageComponent } from './default-page/default-page.component';
import {SharedModule} from "../shared/shared.module";
import { PageHeaderComponent } from './page-header/page-header.component';
import { PageFeaturesComponent } from './page-features/page-features.component';
import {FooterComponent} from "../footer/footer.component";
import { PageContentSectionComponent } from './page-content-section/page-content-section.component';
import {ContactComponent} from "../contact/contact.component";
import { PageStepperContentComponent } from './page-stepper-content/page-stepper-content.component';



@NgModule({
    declarations: [
        DefaultPageComponent,
        PageHeaderComponent,
        PageFeaturesComponent,
        FooterComponent,
        PageContentSectionComponent,
        ContactComponent,
        PageStepperContentComponent
    ],
    exports: [
        FooterComponent
    ],
    imports: [
        SharedModule
    ]
})
export class PagesModule { }
