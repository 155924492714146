<div class="px-4 py-8 md:px-6 lg:px-8 overflow-hidden" [ngClass]="{'bg-white' : stepper?.whiteBackground}">
  <div class="font-bold text-900 text-3xl mb-3 text-center">{{stepper?.title}}</div>
  <div class="text-700 text-center mb-5 line-height-3"></div>
  <div class="flex lg:justify-content-center mb-5" *ngFor="let step of stepper?.steps">
    <ng-container *ngIf="!getIsEven(step?.stepNumber)">
    <div class="py-3 pr-8 pl-3 w-30rem hidden lg:block">
      <img [src]="step?.image?.url" alt="Image" class="w-full mr-8">
    </div>
    <div class="flex flex-column align-items-center w-2rem">
      <span class="p-bg-secondary text-0 flex align-items-center justify-content-center border-circle" style="min-width:2.5rem; min-height: 2.5rem">{{step.stepNumber}}</span>
      <div class="h-full p-bg-secondary" style="width: 2px; min-height: 4rem"></div>
    </div>
    <div class="py-3 pl-5 lg:pl-8 pl-3 lg:w-30rem">
      <div class="text-900 text-xl mb-2 font-medium">{{step.title}}</div>
      <span class="block text-700 line-height-3 mb-3">{{step.description}}</span>
      <img [src]="step?.image?.url" alt="Image" class="w-full mt-3 block lg:hidden">
    </div>
    </ng-container>
    <ng-container *ngIf="getIsEven(step?.stepNumber)">
      <div class="py-3 pl-5 pr-3 lg:pr-8 lg:pl-3 lg:w-30rem flex-order-1 lg:flex-order-0">
        <div class="text-900 text-xl mb-2 font-medium">{{step?.title}}</div>
        <span class="block text-700 line-height-3 mb-3">{{step?.description}}</span>
        <img [src]="step?.image?.url" alt="Image" class="w-full mt-3 block lg:hidden">
      </div>
      <div class="flex flex-column align-items-center w-2rem flex-order-0 lg:flex-order-1">
        <span class="bg-primary text-0 flex align-items-center justify-content-center border-circle" style="min-width:2.5rem; min-height: 2.5rem">{{step?.stepNumber}}</span>
        <div class="h-full bg-primary" style="width: 2px; min-height: 4rem"></div>
      </div>
      <div class="py-3 pl-8 pr-3 w-30rem hidden lg:block flex-order-2">
        <img [src]="step?.image?.url" alt="Image" class="w-full mr-8">
      </div>
    </ng-container>
  </div>
</div>
