<div [ngClass]="{'bg-white' : content?.whiteBackground}">
  <div class="container">
    <div class="grid">
      <ng-container *ngIf="content?.image">
        <div class="col-12 md:col-5 pt-4">
          <img [src]="content?.image?.url" class="w-full">
        </div>
        <div class="col-12 md:col-7 sm:pl-8 sm:pt-8" [innerHTML]="content?.content?.html"></div>
      </ng-container>
      <ng-container *ngIf="!content?.image">
        <div class="col-12" [innerHTML]="content?.content?.html"></div>
      </ng-container>
    </div>
  </div>
</div>
