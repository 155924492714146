import { Injectable } from '@angular/core';
import {Page, PageGQL, PagesGQL, PageWhereInput, PageWhereUniqueInput} from "../../generated/cms/graphql";
import {map} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CmsService {

  constructor(
    private pagesGQL: PagesGQL,
    private pageGQL: PageGQL
  ) { }

  getPages(where?: PageWhereInput) {
    return this.pagesGQL.watch({
      where
    }).valueChanges.pipe(map(({data}) => {
      return data.pages as Page[];
    }))
  }

  getPage(where: PageWhereUniqueInput) {
    return this.pageGQL.watch({
      where
    }).valueChanges.pipe(map(({data}) => {
      return data.page as Page;
    }))
  }
}
