<div class="bg-white" id="contact">
  <div class="container">
    <div class="grid grid-nogutter">
      <div class="col-12 md:col-6 bg-no-repeat" style="background-image: url('assets/images/contact.png')"></div>
      <!--<div class="col-12 md:col-2">
        <div class="text-2xl font-medium my-6 ">Kontaktiere Uns</div>
        <div class="line-height-3 mb-6"></div>
        <ul class="list-none p-0 m-0 mt-6">
          <li class="flex align-items-center mb-3">
            <i class="pi pi-phone mr-2"></i>
            <span>+49 152 24197831</span>
          </li>
          <li class="flex align-items-center">
            <i class="pi pi-inbox mr-2"></i>
            <span>info@covento.de</span>
          </li>
        </ul>
      </div> -->
      <div class="col-12 md:col-6">
        <div class="p-fluid formgrid grid px-4 py-8 md:px-6 lg:px-8">
          <div class="field col-12 lg:col-6 mb-4">
            <input id="firstname" type="text" class="py-3 px-2 text-lg w-full" [(ngModel)]="firstName" pInputText placeholder="Vorname">
          </div>
          <div class="field col-12 lg:col-6 mb-4">
            <input id="lastname" type="text"  class="py-3 px-2 text-lg w-full" [(ngModel)]="lastName" pInputText placeholder="Nachname">
          </div>
          <div class="field col-12 mb-4">
            <input id="company" type="text" class="py-3 px-2 text-lg w-full" [(ngModel)]="company" pInputText placeholder="Unternehmen">
          </div>
          <div class="field col-12 mb-4">
            <input id="email" type="text" pInputText class="py-3 px-2 text-lg w-full" [(ngModel)]="email" placeholder="Email">
          </div>
          <div class="field col-12 mb-4">
            <textarea id="message" pInputTextarea rows="3" [autoResize]="true" class="py-3 px-2 text-lg w-full" [(ngModel)]="message" placeholder="Nachricht"></textarea>
          </div>
          <div class="col-12 text-right">
            <button pButton type="button" [loading]="loading" (click)="onSend()" pRipple class="p-button px-5 py-3 w-auto">Senden</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
