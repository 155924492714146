<div class="bg-primary px-4 py-8 md:px-6 lg:px-8">
  <div class="bg-primary">
    <div class="container">
      <div class="grid">
        <div class="col-12 md:col-3 flex flex-wrap justify-content-start md:mb-0 mb-3">
          <img src="assets/images/logoWhite.png" alt="footer sections" width="100" height="50" class="mr-3">
          <div class="text-gray-300">Covento ist eine Buchungs- und Vergleichsplattform rund um die Event- und Gastrobranche. Wir führen Veranstalter und Dienstleister auf einfache Weise, schnell und zuverlässig zusammen.</div>
        </div>
        <div class="col-12 md:col-3">
          <div class="text-white mb-3"><i class="pi pi-inbox surface-800 border-round p-1 mr-2"></i>info@covento.de</div>
          <div class="text-white mb-3"><i class="pi pi-phone surface-800 border-round p-1 mr-2"></i>+49 152 24197831</div>
        </div>
        <div class="col-12 md:col-3 text-gray-200">
          <div class="text-white font-bold line-height-3 mb-3">Covento</div>
          <a *ngFor="let page of (pages$|async)" [routerLink]="page.slug" class="line-height-3 block cursor-pointer mb-2">{{page.title}}</a>
        </div>
        <div class="col-12 md:col-3 text-gray-200">
          <div class="text-white font-bold line-height-3 mb-3">Links</div>
          <a routerLink="/agb" class="line-height-3 block cursor-pointer mb-2">AGB</a>
          <a routerLink="/impressum" class="line-height-3 block cursor-pointer mb-2">Impressum</a>
          <a routerLink="/datenschutz" class="line-height-3 block cursor-pointer">Datenschutz</a>
        </div>
      </div>
    </div>
  </div>
</div>
