<div class="px-4 py-8 md:px-6 lg:px-8 text-center" [ngClass]="{'bg-white' : featureSection?.whiteBackground}">
  <div class="container">
  <div class="mb-5 font-bold text-3xl">
    <span class="text-900">{{featureSection?.title}}</span>
  </div>
  <!-- <div class="text-700 mb-6">Ac turpis egestas maecenas pharetra convallis posuere morbi leo urna.</div> -->
  <div class="grid">
    <div class="col-12 md:col-4 mb-4 px-5" *ngFor="let feature of featureSection?.features">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <i class="text-4xl" [ngClass]="feature.icon"></i>
            </span>
      <div class="text-900 text-xl mb-3 font-medium">{{feature.title}}</div>
      <div class="text-left">
        <span class="text-700 line-height-3">{{feature.description}}</span>
      </div>
    </div>
  </div>
  </div>
</div>
