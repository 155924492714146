import {Component, Input} from '@angular/core';
import {FeatureSection} from "../../../generated/cms/graphql";

@Component({
  selector: 'app-page-features',
  templateUrl: './page-features.component.html',
  styleUrls: ['./page-features.component.scss']
})
export class PageFeaturesComponent {

  @Input()
  featureSection?: FeatureSection;
}
