export const environment = {
  production: false,
  apiUrl: 'https://covento-s2.azurewebsites.net/graphql',
  restUrl: 'https://covento-s2.azurewebsites.net',
  marketPlaceUrl: 'https://dev.plattform.covento.de',
  myCoventoUrl: 'https://dev.my.covento.de',
  websiteUrl: 'https://dev.covento.de',
  hrUrl:  'https://dev.planning.covento.de',
  cmsUrl: 'https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/clgl4dxli3tlz01ug2h1y3n83/master'
};
